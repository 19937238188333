import { BadgeCheck } from "lucide-react";
import Zoom from "react-medium-image-zoom";

import { getCloudinaryImg } from "~/ui/utils.ts";

export type Features = {
  title: string;
  subtitle: string;
  description: string;
  perks: string[];
};

const features: Features = {
  title: "Cours de Tournage pour Débutants",
  subtitle: "Cours débutant",
  description:
    "Découvrez la poterie à votre rythme, mais avec des bases solides. Si vous travaillez, pas de souci, l'atelier est ouvert à horaires convenant au personnes travaillant la journée.",
  perks: [
    "10h à 19h tous les jours, sauf le Mercredi.",
    "Tous les outils et matières premières à votre disposition",
    "Vous êtes accompagné individuellement",
  ],
};

export const Features = () => {
  return (
    <section id="features">
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
        </svg>
      </div>
      <div className="radius-for-skewed py-20">
        <div className="px-4 lg:container lg:mx-auto lg:max-w-6xl">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="mb-12 w-full px-4 lg:mb-0 lg:w-1/2">
              <div className="max-w-md">
                <span className="font-bold text-purple-600 dark:text-purple-300">
                  {features.subtitle}
                </span>
                <h2 className="mb-3 font-heading text-4xl font-bold lg:text-5xl">
                  {features.title}
                </h2>
                <p className="mb-6 max-w-sm leading-loose text-muted-foreground">
                  {features.description}
                </p>
                <ul className="font-bold text-muted-foreground">
                  {features.perks.map((perk, index) => (
                    <li className="mb-2 flex items-center" key={index}>
                      <BadgeCheck className="mr-4 h-6 w-6 font-bold text-green-700" />
                      <span>{perk}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="mb-4 flex flex-wrap items-end">
                <div className="mb-4 w-2/3 px-3 lg:mb-0">
                  <Zoom>
                    <img
                      src={getCloudinaryImg("/IMG_4525_m1ylae", "w_400,f_auto")}
                      className="h-48 w-full rotate-3 rounded object-cover transition-transform duration-500 hover:rotate-2"
                      alt="Création des élèves émaillées: petits pots, coffrets, récipient feuilles, tasses, émail noir"
                      width="342"
                      height="192"
                      loading="lazy"
                    />
                  </Zoom>
                </div>
                <div className="w-1/3 px-3">
                  <Zoom>
                    <img
                      src={getCloudinaryImg(
                        "/email-tasse_lekpbe",
                        "w_400,f_auto",
                      )}
                      className="h-32 w-full rotate-3 rounded object-cover"
                      alt="Création: Tasse émaillée bleue"
                      width="159"
                      height="128"
                      loading="lazy"
                    />
                  </Zoom>
                </div>
              </div>
              <div className="flex flex-wrap items-start">
                <div className="mb-4 w-1/3 px-3 lg:mb-0">
                  <Zoom>
                    <img
                      src={getCloudinaryImg(
                        "/sgraffito_zvmvrv.jpg",
                        "w_400,f_auto",
                      )}
                      className="h-32 w-full rotate-3 rounded object-cover"
                      alt="Création: 2 Petit pots pour plante avec engobe et technique sgraffito"
                      width="342"
                      height="192"
                      loading="lazy"
                    />
                  </Zoom>
                </div>
                <div className="w-2/3 px-3">
                  <Zoom>
                    <img
                      src={getCloudinaryImg("/2-tasses_tpupwe", "w_400,f_auto")}
                      className="h-48 w-full rotate-3 rounded object-cover"
                      alt="Création: 2 petites tasses biscuit blanches."
                      width="159"
                      height="128"
                      loading="lazy"
                    />
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="radius-for-skewed">
        <div className="px-4 lg:container lg:mx-auto lg:max-w-6xl">
          <div className="mx-auto mb-16 max-w-md text-center">
            <span className="font-bold text-purple-600 dark:text-purple-300">
              Exemple de parcours débutant
            </span>
            <h2 className="font-heading text-4xl font-bold lg:text-5xl">
              Programme des 3 premiers mois
            </h2>
          </div>
          <div className="relative z-0 -mx-4 flex flex-wrap">
            <div
              className="absolute inset-x-0 mx-auto hidden max-w-2xl rounded bg-gradient-to-r from-blue-400 via-pink-500 to-purple-500 py-px lg:block xl:max-w-3xl"
              style={{ top: "6%", zIndex: "-1" }}
            ></div>
            <div className="mb-8 w-full px-4 text-center lg:w-1/3">
              <span className="relative mx-auto mb-6 flex h-16 w-16 items-center justify-center rounded-full bg-blue-400 text-2xl font-bold text-white lg:mb-10">
                1
              </span>
              <h3 className="mb-4 font-heading text-2xl font-bold">
                1er mois d'apprentissage
              </h3>
              <Zoom>
                <img
                  src={getCloudinaryImg("/1er-mois-big", "w_400,f_auto")}
                  className="h-48 w-full rounded object-cover"
                  alt="Création pour le Premier mois"
                  loading="lazy"
                />
              </Zoom>
              <ul className="list-inside list-decimal py-5">
                <li>Préparation de la terre</li>
                <li>Centrage de la terre sur le tour de potier</li>
                <li>Monter les paroies de votre contenant</li>
                <li>Travail du socle avant la mise au feu</li>
                <li>Les bases de l'émail</li>
              </ul>
            </div>
            <div className="mb-8 w-full px-4 text-center lg:w-1/3">
              <span className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-pink-400 text-2xl font-bold text-white lg:mb-10">
                2
              </span>
              <h3 className="mb-4 font-heading text-2xl font-bold">
                2ème mois d'apprentissage
              </h3>
              <Zoom>
                <img
                  src={getCloudinaryImg("/2eme-mois-big", "w_400,f_auto")}
                  className="h-48 w-full rounded object-cover"
                  alt="Création pour le 2eme mois"
                  loading="lazy"
                />
              </Zoom>
              <ul className="list-inside list-decimal py-5">
                <li>Parfaire la forme arrondie de votre récipient</li>
                <li>Donner des angles aigus à la base de votre récipient</li>
                <li>Créer un couvercle</li>
                <li>Créer des paroies plus fines</li>
              </ul>
            </div>
            <div className="w-full px-4 text-center lg:w-1/3">
              <span className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-purple-400 text-2xl font-bold text-white lg:mb-10">
                3
              </span>
              <h3 className="mb-4 font-heading text-2xl font-bold">
                3ème mois d'apprentissage
              </h3>
              <Zoom>
                <img
                  src={getCloudinaryImg("/email-tasse_lekpbe", "w_400,f_auto")}
                  className="h-48 w-full rounded object-cover"
                  alt="Création pour le 3eme mois"
                  loading="lazy"
                />
              </Zoom>
              <ul className="list-inside list-decimal py-5">
                <li>Peinture avec l'engobe</li>
                <li>Ajout d'un élément sculptural à votre poterie</li>
                <li>Application d'émail fantaisie</li>
                <li>Ajout d'anse et poignées à vos poteries</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
        </svg>
      </div>
    </section>
  );
};
