import {
  json,
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import type { MetaFunction } from "@remix-run/react";
import { useLoaderData } from "@remix-run/react";

import { SEOHead } from "~/utils/seo-metadata.ts";
import { FooterContact } from "~/ui/frontend/contact-form.tsx";
import { Features } from "~/ui/frontend/features.tsx";
import { Hero } from "~/ui/frontend/hero.tsx";
import { LatestBlogPosts } from "~/ui/frontend/latest-blog-posts.tsx";
import { Pricing } from "~/ui/frontend/pricing.tsx";
import { Section } from "~/ui/frontend/section.tsx";
import { Showcase } from "~/ui/frontend/showcase.tsx";
import { auth } from "~/storage/public-auth.server.tsx";
import {
  bookableEventQueries,
  organizationRepo,
  resolve,
} from "~/container.server.ts";
import { getBlogPosts } from "./blog/collection.server.ts";

export const headers: HeadersFunction = (
  {
    // actionHeaders,
    // loaderHeaders,
    // parentHeaders,
    // errorHeaders,
  },
) => ({
  "Cache-Control": "s-maxage=3600, stale-while-revalidate=82800",
});

export const links: LinksFunction = () => {
  return [
    {
      rel: "preload",
      fetchpriority: "high",
      as: "image",
      href: "https://res.cloudinary.com/phildl-cloudinary/image/upload/h_512/f_auto,q_auto:eco/atelieregon/hero-unit-md",
      type: "image/webp",
    },
  ];
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    ...SEOHead({
      title: "Atelier Egon | Cours de poterie à la Réunion, 974 Ste Clotilde",
      description:
        "Atelier, cours de Poterie, Tournage et Céramique à St Denis, Ste Clotilde de la Réunion 974. Ouverture en dehors des horaires de travail!",
      canonical: `${ENV.PUBLIC_APP_URL}`,
    }),
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "EntertainmentBusiness",
        image: [
          data?.settings.logo,
          "/images/hero-unit-md.jpg",
          "https://atelier-egon.com/logo.svg",
          "https://atelier-egon.com/features-img-2-big.jpg",
        ],
        name: "Atelier Egon",
        address: {
          "@type": "PostalAddress",
          streetAddress: data?.settings.street,
          addressLocality: data?.settings.city,
          addressRegion: data?.settings.country,
          postalCode: data?.settings.zip,
          addressCountry: data?.settings.country,
        },
        geo: {},
        url: ENV.PUBLIC_APP_URL,
        telephone: data?.settings.phone,
        priceRange: "$$",
        openingHoursSpecification: [
          {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: ["Monday", "Tuesday", "Thursday", "Friday"],
            opens: "10:00",
            closes: "22:00",
          },
          {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: ["Sunday", "Saturday"],
            opens: "10:00",
            closes: "19:00",
          },
        ],
        description: "Cours de poterie à la Réunion, Ste Clotilde",
      },
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const { queries, repos } = resolve({
    queries: { bookableEvent: bookableEventQueries },
    repos: {
      organization: organizationRepo,
    },
  });
  const [user, settings, events] = await Promise.all([
    auth.isAuthenticated(request),
    repos.organization.getSiteSettings(),
    queries.bookableEvent.publicBookableEvents(),
  ]);

  return json(
    {
      user,
      settings,
      posts: getBlogPosts(3),
      events,
    },
    {
      headers: {
        "Cache-Control": "s-maxage=3600, stale-while-revalidate=82800",
      },
    },
  );
}

export const PRICING = {
  title: "Choisissez votre formule",
  subtitle: "Réservation",
  description:
    "Vous pouvez réserver en ligne, confirmation de la réservation par SMS. Pour toute annulation, veuillez nous contacter 24h à l'avance.",
};

export default function Index() {
  const { settings, posts } = useLoaderData<typeof loader>();
  return (
    <main className="relative flex min-h-screen flex-col gap-6">
      <Hero
        title={`${settings.title}!`}
        description={settings.description || ""}
        showForm={true}
      />
      <Features />
      <Section
        id="pricing"
        skewed={["skewed-top-left", "skewed-bottom-right"]}
        className="bg-indigo-950"
      >
        <div className="mb-16 flex w-full flex-wrap items-center">
          <div className="w-full lg:w-1/2">
            <span className="font-bold text-purple-200">
              {PRICING.subtitle}
            </span>
            <h2 className="mb-2 font-heading text-4xl font-bold text-white lg:text-5xl">
              {PRICING.title}
            </h2>
          </div>
          <div className="w-full lg:w-1/2">
            <p className="max-w-xs leading-loose text-gray-100 lg:mx-auto">
              {PRICING.description}
            </p>
          </div>
        </div>
        <Pricing />
      </Section>
      <LatestBlogPosts posts={posts} />
      <Showcase />
      <FooterContact
        phone={settings.phone ?? ""}
        address={{
          name: settings.title ?? "",
          city: settings.city ?? "",
          street: settings.street ?? "",
          street2: settings.street2 ?? "",
          zip: settings.zip ?? "",
          country: settings.country ?? "",
        }}
        socials={{
          facebook: settings.facebook ?? undefined,
          twitter: settings.twitter ?? undefined,
          linkedin: settings.linkedin ?? undefined,
          instagram: settings.instagram ?? undefined,
          youtube: settings.youtube ?? undefined,
        }}
        formAction="/contact"
      />
    </main>
  );
}
